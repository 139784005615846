var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"board-card gl-p-5 gl-rounded-base gl-line-height-normal gl-relative gl-mb-3",class:[
    {
      'multi-select gl-bg-blue-50 gl-border-blue-200': _vm.multiSelectVisible,
      'gl-cursor-grab': _vm.isDraggable,
      'is-disabled': _vm.isDisabled,
      'is-active gl-bg-blue-50': _vm.isActive,
      'gl-cursor-not-allowed gl-bg-gray-10': _vm.item.isLoading,
    },
    _vm.colorClass,
  ],style:(_vm.cardStyle),attrs:{"index":_vm.index,"data-item-id":_vm.item.id,"data-item-iid":_vm.item.iid,"data-item-path":_vm.item.referencePath,"data-testid":"board-card"},on:{"click":function($event){return _vm.toggleIssue($event)}}},[_c('board-card-inner',{attrs:{"list":_vm.list,"item":_vm.formattedItem,"update-filters":true,"index":_vm.index,"show-work-item-type-icon":_vm.showWorkItemTypeIcon},on:{"setFilters":function($event){return _vm.$emit('setFilters', $event)}}},[_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }